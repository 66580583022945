import React, { useEffect } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import lowerEnvJsonSpec from "../openApiSpec/DirectCustomerApiSpec.dev-int.json";
import higherEnvJsonSpec from "../openApiSpec/DirectCustomerApiSpec.qa-prod.json";
import SwaggerDownloadPlugin from './SwaggerDownloadPlugin';

const environment = process.env.REACT_APP_ENVIRONMENT;

function DirectCustomerApi() {

    let swaggerJson;

    if (environment === 'dev2' || environment === 'int') {
        swaggerJson = lowerEnvJsonSpec;
    } else {
        swaggerJson = higherEnvJsonSpec;
    }

    useEffect(() => {
        document.title = "Daimler Truck Business ID Direct Customer API"
    }, []);

    return (
        <div>
            <SwaggerUI spec={swaggerJson} plugins={[SwaggerDownloadPlugin]}></SwaggerUI>
        </div>
    )

}
export default DirectCustomerApi;