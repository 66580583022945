const SwaggerDownloadPlugin = (function (system) {
    return {
        wrapComponents: {
            info: (Original, system) => (props) => {
                function handleDownload() {

                    let swaggerJson = system.getState().toJS().spec;

                    if (swaggerJson && swaggerJson.json) {
                        swaggerJson = swaggerJson.json;
                    }

                    let fileName = 'swagger-spec.json';

                    if (window.location.pathname.includes('backend-api')) {
                        fileName = 'backendApi-swagger-spec.json';
                    } else if (window.location.pathname.includes('direct-customer')) {
                        fileName = 'directCustomerApi-swagger-spec.json';
                    }

                    const swaggerBlob = new Blob([JSON.stringify(swaggerJson, null, 2)], { type: 'application/json' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(swaggerBlob);
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
                return <div>
                    <Original {...props} />
                    <button onClick={handleDownload} className="swagger-download-btn">Download Swagger API Definition</button>
                </div>
            }
        }
    }


})();

export default SwaggerDownloadPlugin;  