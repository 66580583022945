import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DirectCustomerApi from './pages/DirectCustomerApi';
import BackendApi from './pages/BackendApi';
import { AuthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { InteractionStatus } from '@azure/msal-browser';

function App() {

  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {

    if (accounts.length === 0) {
      setTimeout(() => {
        if (inProgress === InteractionStatus.None)
          instance.loginRedirect(loginRequest).catch(e => { console.log(e); });
      }, 500);
    }

  }

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route exact path="/swagger-docs/backend-api" element={<BackendApi />} />
            <Route exact path="/swagger-docs/direct-customer" element={<DirectCustomerApi />} />
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
    </React.Fragment>
  );
}

export default App;
